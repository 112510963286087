import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../classes/Order';
import { OrderPointDetail } from '../classes/OrderPointDetail';
import { CampaignParam } from '../layouts/authorized/order/classes/CampaignParam';
import { environment } from './../../environments/environment';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  
  constructor(private http: HttpClient, private dateService: DateService) { }

  V2_URL:any=environment.API_URL+"/v2/order/image";

  displayedSelectedColumns = [
    { label: 'Order ID', key: 'id', checked: true },
    { label: 'Contract number', key: 'contractNo', checked: true },
    { label: 'Order name', key: 'orderName', checked: true },
    { label: 'Client', key: 'client', checked: true },
    { label: 'PO no', key: 'poNo', checked: false },
    { label: 'Sales manager', key: 'manager', checked: false },
    { label: 'Date created', key: 'createDateTime', checked: false },
    { label: 'Run date', checked: false },
    { label: 'Act. run date', checked: true },
    { label: 'Receive date', key: 'receiveDate', checked: false },
    { label: 'Finalise date', key: 'finaliseDate', checked: false },
    { label: 'Resource', checked: true },
    { label: 'Video time', key: 'videoTime', checked: false },
    { label: 'Loop time', key: 'loopTime', checked: false },
    { label: 'Status', key: 'advertisementStatus', checked: true },
    { label: 'Remark', key: 'remark', checked: false },
  ];

  exportHeaders = [
    { header: 'Order id', key: 'id', checked: true },
    { header: 'Order name', key: 'orderName', checked: true },
    { header: 'Contract no.', key: 'contractNo', checked: true },
    { header: 'Client', key: 'client', checked: true },
    { header: 'Sales manager', key: 'manager', checked: true },
    { header: 'Date created', key: 'createDateTime', checked: true },
    { header: 'Start date', key: 'startDate', checked: true },
    { header: 'End date', key: 'endDate', checked: true },
    { header: 'Act. start date', key: 'actualStartDate', checked: true },
    { header: 'Act. end date', key: 'actualEndDate', checked: true },
    { header: 'Receive date', key: 'receiveDate', checked: true },
    { header: 'Finalise date', key: 'finaliseDate', checked: true },
    { header: 'Screen', key: 'screenQty', checked: true },
    { header: 'Std. screen', key: 'standingScreenQty', checked: true },
    { header: 'Poster', key: 'posterQty', checked: true },
    { header: 'Video time', key: 'videoTime', checked: true },
    { header: 'Loop time', key: 'loopTime', checked: true },
    { header: 'Status', key: 'advertisementStatus', checked: true },
    { header: 'Remark', key: 'remark', checked: true },
  ];

  statusList = [
    'PRE_SIGNING',
    'SIGNED',
    'DESIGNING',
    'WAITING',
    'RUNNING',
    'DELAY',
    'PAUSE',
    'STOP',
    'EXPIRED',
    'SKIP',
  ];

  statusListWithCheck = [
    { label: 'PRE_SIGNING', checked: false },
    { label: 'SIGNED', checked: false },
    { label: 'DESIGNING', checked: false },
    { label: 'WAITING', checked: false },
    { label: 'RUNNING', checked: false },
    { label: 'DELAY', checked: false },
    { label: 'PAUSE', checked: false },
    { label: 'STOP', checked: false },
    { label: 'EXPIRED', checked: false },
    { label: 'SKIP', checked: false },
  ];

  allColumns = [
    { header: 'Play_Date', key: 'date', checked: true },
    { header: 'Program_ID', key: 'programId', checked: true },
    { header: 'Program_Name', key: 'programName', checked: true },
    { header: 'Program_Duration', key: 'programDuration', checked: true },
    { header: 'Wifi_Mac', key: 'wifiMac', checked: true },
  ];

  setDefaultHeader() {
    this.exportHeaders.forEach((header) => (header.checked = true));
  }

  findOrderId() {
    return this.http.get<any>(`${environment.API_URL}/advertisement/orderId`);
  }

  findAllOrder(data: any) {
    let params = new HttpParams();

    if (data.currentPage !== null && data.currentPage !== undefined) {
      params = params
        .set('start', (data.currentPage - 1).toString())
        .set('pageSize', data.pageSize.toString());
    }

    if (data.manager) {
      params = params.set('salesManager', data.manager);
    }

    if (data.status.length > 0) {
      const statusList: any = [];
      data.status.forEach((status) => {
        statusList.push(
          status === 'PRE_SIGNING'
            ? 1
            : status === 'SIGNED'
              ? 2
              : status === 'RUNNING'
                ? 3
                : status === 'EXPIRED'
                  ? 4
                  : status === 'SKIP'
                    ? 5
                    : status === 'DESIGNING'
                      ? 6
                      : status === 'WAITING'
                        ? 7
                        : status === 'DELAY'
                          ? 8
                          : status === 'PAUSE'
                            ? 9
                            : status === 'STOP'
                              ? 10
                              : 0
        );
      });
      params = params.set('status', statusList);
    }

    if (data.client.length > 0) {
      params = params.set('companyIds', data.client);
    }

    if (data.orderId) {
      params = params.set('orderId', data.orderId);
    }

    if (data.contractNo) {
      params = params.set('contractNo', data.contractNo);
    }

    if (data.orderName) {
      params = params.set('orderName', data.orderName);
    }

    if (data.startDateFirst) {
      params = params.set('startDateFrom', data.startDateFirst);
      params = params.set('startDateTo', data.startDateSecond);
    }

    if (data.endDateFirst) {
      params = params.set('endDateFrom', data.endDateFirst);
      params = params.set('endDateTo', data.endDateSecond);
    }
    return this.http.get(`${environment.API_URL}/advertisement`, { params });
  }

  findOrder(companyId: any) {
    let params = new HttpParams().set('suborder', 'yes');
    if (companyId) {
      params = params.set('companyIds', companyId);
    }
    return this.http.get(`${environment.API_URL}/advertisement`, { params });
  }

  findVideos(orderId: any) {
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get(`${environment.API_URL}/dsp/order/files`, {
      params,
    });
  }

  findImpression(orderId: any, type: any) {
    const params = new HttpParams().set('orderId', orderId).set('type', type);
    return this.http.get(`${environment.API_URL}/advertisement/impression`, {
      params,
    });
  }

  updateStatus(orderId: number, orderStatus: string) {
    const order = {
      id: orderId,
      status: orderStatus,
    };
    return this.http.put(`${environment.API_URL}/advertisement/status`, order);
  }

  update(data: any) {
    return this.http.put(`${environment.API_URL}/advertisement`, data);
  }

  campaignUploadFiles(id: any, data: any) {
    return this.http.post(
      `${environment.API_URL}/file/upload/campaignfile/` + id,
      data
    );
  }

  calculateCampaignImpression(campaign: any) {
    let totalImpression = 0;
    if (campaign.impressionPerDays) {
      const impressionPerDays = campaign.impressionPerDays;
      impressionPerDays.split(',').forEach((impression) => {
        const imp = +impression;
        totalImpression += imp;
      });
    }
    return totalImpression;
  }

  checkDates(
    advertisementId: any,
    startDate: any,
    endDate: any,
    videoTime: any
  ) {
    let params = new HttpParams()
      .set('advertisementId', advertisementId)
      .set('startDate', startDate)
      .set('endDate', endDate);

    if (videoTime) {
      params = params.set('videoTime', videoTime);
    }
    return this.http.get(`${environment.API_URL}/advertisement/check`, {
      params,
    });
  }

  findPoints(orderId: number, selectProperty: any) {
    let params = new HttpParams().set('advertisementId', orderId.toString());
    if (selectProperty && selectProperty.length > 0) {
      params = params.set('buildingIds', selectProperty);
    }
    return this.http.get(`${environment.API_URL}/advertisementDetail/points`, {
      params,
    });
  }

  updatePoints(data: any) {
    return this.http.put(
      `${environment.API_URL}/advertisementDetail/manageStatus`,
      data
    );
  }

  isActualDates(id: number) {
    return this.http.get(
      `${environment.API_URL}/advertisement/isActualDates/${id}`
    );
  }

  findById(id: number) {

    return this.http.get(`${environment.API_URL}/advertisement/${id}`);
  }

  findAllProperty(orderId: any) {
    let params = new HttpParams();
    if (orderId) {
      params = params.set('orderId', orderId);
    }
    return this.http.get(`${environment.API_URL}/advertisement/property`, {
      params,
    });
  }

  findAllOrderProperty(orderId: any) {
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get(
      `${environment.API_URL}/advertisement/order/property`,
      { params }
    );
  }

  findAllImage(orderId: any, type: string, pageIndex: any, pageSize: any) {
    const params = new HttpParams()
      .set('orderId', orderId)
      .set('type', type)
      .set('pageIndex', (pageIndex - 1).toString())
      .set('pageSize', pageSize);
    return this.http.get(`${environment.API_URL}/advertisement/image`, {
      params,
    });
  }

  approvedOrderImage(imageId: any, status: any) {
    const data = {
      imageId,
      type: status,
    };
    return this.http.put(
      `${environment.API_URL}/advertisement/image/approve`,
      data
    );
  }

  findLogs(orderIds: any, startDate, pageIndex: any, pageSize: any, logTypes: string, selectedProperties: any,
    dateLogs: any) {
    let params = new HttpParams()
      .set('orderIds', orderIds)
      .set('startDate', startDate)
      .set('pageIndex', pageIndex)
      .set('pageSize', pageSize)
      .set('logTypes', logTypes);

    if (selectedProperties && selectedProperties.length) {
      params = params.set('buildingIds', selectedProperties);
    }
    if (dateLogs && dateLogs.length) {
      params = params.set('searchStartDate', this.dateService.convertDateAndTime(dateLogs[0]));
      params = params.set('searchEndDate', this.dateService.convertDateAndTime(dateLogs[1]));
    }
    return this.http.get(`${environment.API_URL}/dsp/order/logs`, { params });
  }

  findOrderProperty(orderId: any) {
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get(`${environment.API_URL}/advertisement/property`, {
      params,
    });
  }

  getLogSummary(orderIds: any, startDate, selectedProperties: any, dateLogs: any) {
    let params = new HttpParams()
      .set('orderIds', orderIds)
      .set('startDate', startDate);
    if (selectedProperties && selectedProperties.length) {
      params = params.set('buildingIds', selectedProperties);
    }
    if (dateLogs && dateLogs.length) {
      params = params.set('searchStartDate', this.dateService.convertDateAndTime(dateLogs[0]));
      params = params.set('searchEndDate', this.dateService.convertDateAndTime(dateLogs[1]));
    }
    return this.http.get(`${environment.API_URL}/dsp/order/logs/summary`, {
      params,
    });
  }

  getReportData(startDate: string, endDate: string, propertyIds: any): Observable<Order[]> {
    let params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate);
    if (propertyIds && propertyIds.length > 0) {
      params = params.set('propertyIds', propertyIds);
    }
    return this.http.get<Order[]>(`${environment.API_URL}/advertisement/report`, {
      params,
    });
  }

  findPropertyInfo(propertyId: any) {
    const params = new HttpParams().set('buildingId', propertyId);
    return this.http.get(`${environment.API_URL}/sales/property/info`, {
      params,
    });
  }


  findAllResources(orderId: number, cityIds: any, propertyIds: any): Observable<OrderPointDetail[]> {




    let params = new HttpParams();
    if (propertyIds && propertyIds.length) {
      params = params.set('propertyIds', propertyIds);
    }
    if (cityIds && cityIds.length) {
      params = params.set('cityIds', cityIds);
    }
    return this.http.get<OrderPointDetail[]>(
      `${environment.API_URL}/advertisement/point/detail/${orderId}`,
      { params }
    );


  }

  renew(data: any) {

    return this.http.post(`${environment.API_URL}/ims/order/renew`, data);
  }

  takeImages(data: any) {
    return this.http.put(`${environment.API_URL}/advertisement/image/generate`, data);
  }

  takeDeliveryImages(data: any) {
    return this.http.put(`${environment.API_URL}/advertisement/image/delivery/generate`, data);
  }

  findOrderImages(data: any) {

    let params = new HttpParams()
      .set('campaignId', data.campaignId)
      .set('date', data.date)
      .set('exportType', data.exportType);
    return this.http.get(`${environment.API_URL}/advertisement/image/export`, { params });
  }

  public takeScreenshot(data: any) {

    return this.http.post(`${environment.API_URL}/advertisement/screenshot`, data);
  }

  // findAllScreenshot(campaignId: number,cityIds:any=[],properyIds:any=[]) {
  //   return this.http.get(
  //     `${environment.API_URL}/advertisement/screenshot/${campaignId}`
  //   );
  // }

  findAllScreenshot(campaignId: number, cityIds: any, propertyIds: any) {
    let params = new HttpParams();

    if (propertyIds && propertyIds.length) {
      params = params.set('propertyIds', propertyIds);
    }
    if (cityIds && cityIds.length) {
      params = params.set('cityIds', cityIds);
    }

    return this.http.get(
      `${environment.API_URL}/advertisement/screenshot/${campaignId}`,
      { params }
    );
  }
  exportLogSummaryExcel(campaignId: number) {

    return this.http.get(
      `${environment.API_URL}/campaign/log/summary/${campaignId}`
    );
  }

  exportLogSummaryPDF(campaignId: number) {

    return this.http.get(
      `${environment.API_URL}/campaign/log/summary/${campaignId}/pdf`
    );
  }

  findAllActivePO(filterData: any): any {

    let params = new HttpParams().set("pageIndex", filterData.pageIndex).set('pageSize', filterData.pageSize);

    if (filterData.status && filterData.status.length) {
      params = params.set('status', filterData.status);
    }
    if (filterData.companyNames && filterData.companyNames.length) {
      params = params.set('companyNames', filterData.companyNames);
    }
    if (filterData.salesManager) {
      params = params.set('salesManager', filterData.salesManager);
    }
    return this.http.get(`${environment.API_URL}/po/active/page`, { params });
  }

  findActiveNonActiveScreens(orderId: any, selectedProperties: any, action: any) {

    let params = new HttpParams().set("orderId", orderId).set('action', action);


    if (selectedProperties && selectedProperties.length) {
      params = params.set('buildingIds', selectedProperties);
    }
    return this.http.get(`${environment.API_URL}/advertisement/active-nonactive-screens`, { params });
  }
  findAllCampaignImage(data: CampaignParam) {
    let params = new HttpParams();

    if (data.propertyIds && data.propertyIds.length) {
      params = params.set('propertyIds', data.propertyIds);
    }
    if (data.cityIds && data.cityIds.length) {
      params = params.set('cityIds', data.cityIds);
    }
    if (data.imageDate && data.imageDate.length) {
      params = params.set('imageDate', data.imageDate);
    }
    data.pageIndex = data.pageIndex > 0 ? data.pageIndex : 1;
    params = params.set('pageIndex', data.pageIndex.toString());
    params = params.set('pageSize', data.pageSize.toString());

    return this.http.get(
      `${environment.API_URL}/advertisement/campaignImages/${data.orderId}`,
      { params }
    );
  }

  downloadImage() {

    const url = `${environment.API_URL}/campaign/image/download/zip?campaignId=5796&date=2024-09-09`;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadImagebyOrderId(orderId:number,date:string):Observable<any>
  {
    const params = new HttpParams()
    .set("orderId", orderId.toString())
    .set("date", date);
  
  const api_url = this.V2_URL + "/download";
  return this.http.get(api_url, { params, responseType: 'text' });
}

  
  downloadPdfOfImageByOrderId(orderId:number, date:string): Observable<any> {
    const params = new HttpParams()
      .set("orderId", orderId.toString())
      .set("date", date);
    
    const api_url = this.V2_URL + "/download/pdf";
    return this.http.get(api_url, { params, responseType: 'text' });
  }
}